<template>
  <v-layout
    row
    wrap
    fill-height
  >
    <v-flex xs12>
      <v-card
        v-if="dataStore.cart"
        class="fill-height gfp-adv-common-cart-background"
        flat
      >
        <div ref="vCardHeader">
          <v-toolbar
            card
            class="gfp-adv-common-cart-toolbar-background"
            height="46"
            prominent
          >
            <v-badge
              :value="totalCartItemsCount > 0"
              color="gfp-adv-common-cart-toolbar-icon-badge-background"
              overlap
              right
            >
              <span
                v-if="totalCartItemsCount > 0"
                slot="badge"
                class="gfp-adv-common-cart-toolbar-icon-badge-text--text"
              >
                {{ totalCartItemsCount }}
              </span>

              <v-icon color="gfp-adv-common-cart-toolbar-icon">
                shopping_cart
              </v-icon>
            </v-badge>

            <v-toolbar-title>
              <div class="subheading gfp-adv-common-cart-toolbar-title--text">
                <strong>{{ $t('Cart.Title') }}</strong>
              </div>
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              icon
              :color="getContrastColor($vuetify.theme['gfp-adv-common-cart-toolbar-background'].base) === 'white' ? 'white--text' : 'black--text'"
              @click="drawerVisible=false"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider />

          <v-card-title
            v-if="dataStore.cart.IsClosed"
            ref="vCardTitle"
            class="pa-2"
          >
            <v-alert
              :dismissible="false"
              :value="dataStore.cart.IsClosed"
              class="caption"
              outline
              style="width: 100%;"
              type="error"
            >
              <span
                style="width:100%;"
                v-html="$t('Common.Error.StoreClosed')"
              />
            </v-alert>
          </v-card-title>
        </div>

        <div
          ref="vCardActions"
        >
          <template v-if="isCartEnabled">
            <v-card-actions>
              <cart-order-type :minimized="true" />
            </v-card-actions>

            <v-divider />

            <v-card-actions
              v-if="!orderTotalAmountValid"
              class="justify-center pb-0 font-weight-bold gfp-adv-common-cart-text--text"
            >
              <div>{{ $t('Checkout.Error.PaymentMethodMinimumOrder.BodyAlt', {minimumOrderTotalDiff: $options.filters.currency(orderMinTotalAmountDiff)}) }}</div>
            </v-card-actions>

            <v-card-actions class="justify-center pb-2">
              <v-btn
                ref="buyNowButton"
                :disabled="cartItems.length <= 0"
                :to="{name: 'CheckoutDetails'}"
                block
                class="elevation-0"
                color="gfp-adv-common-cart-button-buynow-background gfp-adv-common-cart-button-buynow-text--text"
                large
                @click="drawerVisible=false"
              >
                {{ $t('Cart.Buy') | sanitizeDiacritics }}
                {{ cartTotalAmount | currency }}
              </v-btn>
            </v-card-actions>

            <template v-if="hasActiveCoupons">
              <v-divider />

              <v-card-actions class="pa-0 ma-0">
                <v-layout
                  class="pl-2 pr-2"
                  row
                  wrap
                >
                  <v-flex
                    v-if="!cartHasCoupon && canCustomerAddCoupon"
                    xs12
                  >
                    <v-expansion-panel class="elevation-0">
                      <v-expansion-panel-content
                        color="transparent"
                        class="transparent"
                      >
                        <div
                          slot="header"
                          class="subheading gfp-adv-common-cart-coupon-text--text font-weight-medium pl-1"
                        >
                          <div class="left mr-3">
                            <v-icon color="gfp-adv-common-cart-coupon-icon">
                              local_offer
                            </v-icon>
                          </div>
                          <div>{{ $t('Cart.Coupon.Title') }}</div>
                          <div
                            v-if="!autoApplyCouponMinTotalAmountValid && hasActiveCouponForAutoApply"
                            class="body-2"
                          >
                            {{
                              $t('Checkout.Error.AutoApplyCouponMinimumOrder.BodyAlt', {
                                discount: autoApplyCouponDiscount,
                                minimumOrderTotalDiff: $options.filters.currency(autoApplyCouponMinTotalAmountDiff)
                              })
                            }}
                          </div>
                        </div>

                        <v-card color="transparent">
                          <v-card-text class="mb-0 pt-0">
                            <v-text-field
                              v-model="couponValue"
                              :label="$t('Cart.Coupon.Label')"
                              color="primary"
                              clearable
                            />
                            <v-btn
                              block
                              class="elevation-0 gfp-adv-common-cart-coupon-button-text--text"
                              color="gfp-adv-common-cart-coupon-button-background"
                              dark
                              @click="couponAdd()"
                            >
                              {{ $t('Cart.Coupon.Button') | sanitizeDiacritics }}
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-flex>

                  <v-flex
                    v-else-if="!cartHasCoupon && !canCustomerAddCoupon && !autoApplyCouponMinTotalAmountValid && hasActiveCouponForAutoApply"
                    xs12
                  >
                    <div class="justify-center font-weight-bold gfp-adv-common-cart-text--text ml-3 my-2">
                      <div class="left mr-3">
                        <v-icon color="gfp-adv-common-cart-coupon-icon">
                          local_offer
                        </v-icon>
                      </div>

                      <span>
                        {{
                          $t('Checkout.Error.AutoApplyCouponMinimumOrder.BodyAlt', {
                            discount: autoApplyCouponDiscount,
                            minimumOrderTotalDiff: $options.filters.currency(autoApplyCouponMinTotalAmountDiff)
                          })
                        }}
                      </span>
                    </div>
                  </v-flex>

                  <v-flex
                    v-else-if="cartHasCoupon"
                    xs12
                  >
                    <v-list
                      dense
                      light
                      two-line
                    >
                      <v-list-tile ripple>
                        <div class="left mr-3">
                          <v-icon color="gfp-adv-common-cart-coupon-icon">
                            local_offer
                          </v-icon>
                        </div>

                        <v-list-tile-content>
                          <v-list-tile-title class="subheading font-weight-medium gfp-adv-common-cart-coupon-text--text">
                            {{ $t('Cart.Coupon.Name') }}
                          </v-list-tile-title>

                          <v-list-tile-sub-title class="gfp-adv-common-cart-coupon-text--text">
                            {{ dataStore.cart.CartTotals.coupon.code }}
                          </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action>
                          <v-list-tile-action-text class="body-2 gfp-adv-common-cart-coupon-text--text">
                            -{{ dataStore.cart.CartTotals.coupon.amount | currency }}
                          </v-list-tile-action-text>

                          <v-btn
                            v-if="!hasActiveCouponForAutoApply"
                            color="gfp-adv-common-cart-button-remove"
                            flat
                            icon
                            @click.stop="couponRemove()"
                          >
                            <v-icon>remove_circle_outline</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </template>

            <template v-if="appConfig.LOCATION_DATA.OrderTips">
              <v-divider />

              <v-card-actions class="pa-0 ma-0">
                <v-layout
                  class="pl-2 pr-2"
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-expansion-panel class="elevation-0">
                      <v-expansion-panel-content
                        color="transparent"
                        class="transparent"
                      >
                        <div
                          slot="header"
                          class="subheading gfp-adv-common-cart-tip-text--text font-weight-medium pl-1"
                        >
                          <div class="left mr-3">
                            <v-icon color="gfp-adv-common-cart-tip-icon">
                              {{ $t('Cart.Tip.Icon') }}
                            </v-icon>
                          </div>

                          {{ $t('Cart.Tip.Title') }} <span v-if="dataStore.cart.CartTotals.tip_amount">{{ dataStore.cart.CartTotals.tip_amount.amount | currency }}</span>
                        </div>

                        <v-card class="mb-2">
                          <delivery-tip small />
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </template>

            <template v-if="dataStore.cart.CartTotals.delivery">
              <v-divider />

              <v-card-actions class="pa-0 ma-0">
                <v-layout
                  class="pl-2 pr-2"
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-list
                      dense
                      light
                    >
                      <v-list-tile ripple>
                        <v-list-tile-content>
                          <v-list-tile-title class="gfp-adv-common-cart-coupon-text--text">
                            <div class="left mr-3">
                              <v-icon
                                color="gfp-adv-common-cart-coupon-icon"
                                size="24"
                              >
                                directions_bike
                              </v-icon>
                            </div>

                            <template v-if="dataStore.cart.CartTotals.delivery.lang && dataStore.cart.CartTotals.delivery.lang[$i18n.locale]">
                              {{ dataStore.cart.CartTotals.delivery.lang[$i18n.locale].name }}
                            </template>
                            <template v-else>
                              {{ dataStore.cart.CartTotals.delivery.title }}
                            </template>
                          </v-list-tile-title>
                        </v-list-tile-content>

                        <v-list-tile-action>
                          <v-list-tile-action-text class="gfp-adv-common-cart-coupon-text--text">
                            {{ dataStore.cart.CartTotals.delivery.amount | currency }}
                          </v-list-tile-action-text>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </template>

            <template v-if="dataStore.cart.CartTotals.extra_charge">
              <template v-for="(charge, index) in dataStore.cart.CartTotals.extra_charge">
                <v-divider :key="`charge-divider-${index}`" />

                <v-card-actions
                  :key="`charge-${index}`"
                  class="pa-0 ma-0"
                >
                  <v-layout
                    class="pl-2 pr-2"
                    row
                    wrap
                  >
                    <v-flex xs12>
                      <v-list
                        dense
                        light
                      >
                        <v-list-tile ripple>
                          <v-list-tile-content>
                            <v-list-tile-title class="gfp-adv-common-cart-coupon-text--text">
                              <div class="left mr-3">
                                <v-icon
                                  color="gfp-adv-common-cart-coupon-icon"
                                  size="24"
                                >
                                  add
                                </v-icon>
                              </div>

                              <template v-if="charge.lang && charge.lang[$i18n.locale]">
                                {{ charge.lang[$i18n.locale].Name }}
                              </template>
                              <template v-else>
                                {{ charge.title }}
                              </template>
                            </v-list-tile-title>
                          </v-list-tile-content>

                          <v-list-tile-action>
                            <v-list-tile-action-text class="gfp-adv-common-cart-coupon-text--text">
                              {{ charge.amount | currency }}
                            </v-list-tile-action-text>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list>
                    </v-flex>
                  </v-layout>
                </v-card-actions>
              </template>
            </template>
          </template>

          <v-divider />

          <v-card-text
            ref="vCardBody"
            class="pt-2 pb-2 pl-2 pr-2"
          >
            <v-layout
              row
              wrap
            >
              <v-flex
                class="pa-0 ma-0"
                xs12
              >
                <template v-for="(category, index) in cartItems">
                  <div
                    v-if="category.Category && category.Category.Lang && category.Category.Lang[$i18n.locale]"
                    :key="`cart-category-${index}-${category.Id}`"
                    :style="`border-left: #${category.Category.Color ? category.Category.Color : 'e1e1e1'} 3px solid; background-color: ${$vuetify.theme['gfp-adv-common-cart-items-category-background'].base};`"
                    class="mt-1"
                  >
                    <v-subheader
                      class="subheading pl-3 px-1 gfp-adv-common-cart-items-category-text--text"
                      style="height: 35px;"
                    >
                      {{ category.Category.Lang[$i18n.locale].Name }}
                    </v-subheader>
                  </div>

                  <template v-for="cartItem in category.Items">
                    <v-list
                      v-if="cartItem.Lang && cartItem.Lang[$i18n.locale]"
                      :key="`${cartItem.RowId}-${index}}`"
                      class="pa-0 mt-1 mb-1"
                      dense
                      light
                      two-line
                    >
                      <v-list-tile
                        :disabled="cartItem.removeItemLoading"
                        :inactive="cartItem.removeItemLoading"
                        class="gfp-adv-common-cart-items-product-background"
                        ripple
                        @click.stop="onShowMenuOptionsDialog({menuItem: cartItem, editMode: true})"
                      >
                        <v-list-tile-content class="justify-start pt-1">
                          <v-list-tile-title>
                            <div class="gfp-adv-common-cart-items-product-text-title--text">
                              <div class="gfp-adv-common-cart-items-product-text-title-quantity--text font-weight-bold d-inline-block">
                                <template v-if="cartItem.UnitId > 1">
                                  {{ formatUnitQuantity($i18n.locale, cartItem.UnitQuantity, getMeasurementUnitById(cartItem.UnitId)) }}
                                </template>
                                <template v-else>
                                  {{ cartItem.Quantity }} x
                                </template>
                              </div>
                              {{ cartItem.Lang[$i18n.locale].Name }}
                            </div>
                          </v-list-tile-title>

                          <v-list-tile-sub-title
                            v-if="cartItem.Options && cartItem.Options[$i18n.locale]"
                            class="gfp-adv-common-cart-items-product-text-description--text"
                          >
                            {{ cartItem.Options[$i18n.locale].join(', ') }}&nbsp;
                          </v-list-tile-sub-title>
                        </v-list-tile-content>

                        <v-list-tile-action>
                          <v-list-tile-action-text class="gfp-adv-common-cart-items-product-text-price--text">
                            {{ cartItemTotal(cartItem, true) | currency }}
                          </v-list-tile-action-text>

                          <v-btn
                            :disabled="cartItem.removeItemLoading"
                            :loading="cartItem.removeItemLoading"
                            color="gfp-adv-common-cart-button-remove"
                            small
                            flat
                            icon
                            @click.stop="cartRemoveItem(cartItem)"
                          >
                            <v-icon>remove_circle_outline</v-icon>
                          </v-btn>
                        </v-list-tile-action>
                      </v-list-tile>
                    </v-list>
                  </template>
                </template>
              </v-flex>
            </v-layout>
          </v-card-text>
        </div>

        <div v-if="cartItems.length <= 0">
          <div class="text-xs-center gfp-adv-common-cart-items-category-text--text pa-2">
            <v-icon
              class="mb-3 gfp-adv-common-cart-items-category-background--text"
              size="96"
            >
              add_shopping_cart
            </v-icon>
            <div class="title mb-2">
              {{ $t('Cart.Empty.Title') }}
            </div>
            <div class="subheading">
              {{ $t('Cart.Empty.Subtitle') }}
            </div>
          </div>
        </div>
      </v-card>
    </v-flex>

    <menu-options-dialog
      :menu-options-dialog-edit-mode="menuOptionsDialogEditMode"
      :menu-options-dialog-visible.sync="menuOptionsDialogVisible"
      :selected-menu-item="selectedMenuItem"
    />
  </v-layout>
</template>

<script>
import MenuOptionsDialog      from './MenuOptionsDialog'
import CartOrderType          from './CartOrderType'
import DeliveryTip            from '@/components/cart/DeliveryTip'
import OrderTypeTime          from '@/mixins/orders/orderTypeTime'
import CartCommon             from '@/mixins/orders/cartCommon'
import AppData                from '@/mixins/appdata'
import AnalyticsCommon        from '@/mixins/analytics/analyticsCommon'
import Watchable              from '@/mixins/watchable'
import DataEventEnum          from '@/lib/services/enum/DataEventEnum'
import DataEventService       from '@/lib/services/event/DataEventService'
import { getContrastColor }   from '@/lib/color'
import { formatUnitQuantity } from '@/lib/currency/currency'

export default {
  name      : 'CartDrawer',
  components: {
    DeliveryTip,
    CartOrderType,
    'menu-options-dialog': MenuOptionsDialog
  },
  directives: {},
  mixins    : [AppData, Watchable, CartCommon, OrderTypeTime, AnalyticsCommon],
  props     : ['drawer'],
  data () {
    return {
      selectedDeliveryTip      : null,
      menuOptionsDialogVisible : false,
      menuOptionsDialogEditMode: false,
      selectedMenuItem         : null,
      couponValue              : ''
    }
  },
  computed: {
    drawerVisible: {
      get () {
        return this.drawer
      },
      set (val) {
        this.$emit('update:drawer', val)
      }
    },
    totalCartItemsCount () {
      return this.dataStore?.cart?.CartTotalQuantity || 0
    }
  },
  watch: {
    IsLoading (newVal) {
      this.$bus.$emit('cart-loading', newVal)
    },
    totalCartItemsCount () {
      this.onCartItemsChange()
    },
    'dataStore.userInfo': function () {
      this.confirmDefaultAddress()
    }
  },
  created () {},
  mounted () {
    this.$bus.$on('save:menuOptionsItem', this.saveMenuOptions)
    this.$bus.$on('show-menu-options-dialog', this.onShowMenuOptionsDialog)
    this.$bus.$on('user-logged-out', this.getCart)
    this.$bus.$on('user-logged-in', this.getCart)
    this.$bus.$on('update-cart', this.getCart)
    this.$bus.$on('cart-tip-add', this.tipAdd)
    this.$bus.$on('cart-tip-remove', this.tipRemove)
    this.getCart()
  },
  destroyed () {
    this.$bus.$off('save:menuOptionsItem', this.saveMenuOptions)
    this.$bus.$off('show-menu-options-dialog', this.onShowMenuOptionsDialog)
    this.$bus.$off('user-logged-out', this.getCart)
    this.$bus.$off('user-logged-in', this.getCart)
    this.$bus.$off('update-cart', this.getCart)
    this.$bus.$off('cart-tip-add', this.tipAdd)
    this.$bus.$off('cart-tip-remove', this.tipRemove)
  },
  methods: {
    formatUnitQuantity,
    getContrastColor,
    confirmDefaultAddress () {
      if (this.$route?.params?.redirect || this.isCatalogOnly) return

      if (this.dataStore.isAuthenticated && this.dataStore.userInfo && this.$route.name !== 'CheckoutDetails') {
        const Addresses = JSON.parse(JSON.stringify(this.dataStore.userInfo.Addresses))
        const Info = JSON.parse(JSON.stringify(this.dataStore.userInfo.Info))
        Info.Address = null

        if (Addresses.length > 0) Info.Address = this.dataStore.userInfo.Addresses.find(address => address.IsDefault) || Addresses[0]

        this.CartPromiseQueue.push(() => window.API.post(window.APICall.checkoutAddress, Info)
          .then(response => {
            if (response.data && response.data.status === 200) {
              this.$set(this.dataStore, 'cart', response.data.data.Cart)
            }
          })
          .catch(e => {})
          .finally(() => {})
        )
      }
    },

    cartSetData (data, setOrderType = true) {
      // this.unwatch('dataStore.cart.OrderType')
      this.unwatch('dataStore.tmpOrderType')

      this.$set(this.dataStore, 'cart', data)
      this.dataStore.tmpOrderType = this.dataStore.cart.OrderType

      if (setOrderType) {
        if (!this.hasCollection && this.hasDelivery) {
          this.dataStore.tmpOrderType = this.dataStore.cart.OrderType = '1'
          this.$nextTick(() => {
            this.setOrderType()
          })
        }

        if (!this.hasDelivery && this.hasCollection) {
          this.dataStore.tmpOrderType = this.dataStore.cart.OrderType = '2'
          this.$nextTick(() => {
            this.setOrderType()
          })
        }
      }

      // this.watch('dataStore.cart.OrderType', this.setOrderType)
      this.watch('dataStore.tmpOrderType', this.setOrderType)

      // console.log('OrderType:', this.dataStore.cart.OrderType, 'tmpOrderType:', this.dataStore.tmpOrderType)
    },

    cartParseMessages (data) {
      const CartMessages = Array.isArray(data.Cart && data.Cart.Messages) ? [] : Object.values(data.Cart && data.Cart.Messages || {}) || []
      if (CartMessages.length > 0) {
        this.$bus.$emit('show-snackbar', {
          title: '',
          body : CartMessages[0],
          type : 'error',
          icon : ''
        })

        if (data.Cart && data.Cart.Messages && data.Cart.Messages.hasOwnProperty('ValidateCartMenu')) {
          this.$bus.$emit('init-menu')
        }
      } else if (data.Messages) {
        if (data.Messages.Success) {
          this.$bus.$emit('show-snackbar', {
            title: '',
            body : data.Messages.Success,
            type : 'success',
            icon : ''
          })
        } else if (data.Messages.Error) {
          this.$bus.$emit('show-snackbar', {
            title: '',
            body : data.Messages.Error,
            type : 'error',
            icon : ''
          })
        } else if (data.Messages.Warning) {
          this.$bus.$emit('show-snackbar', {
            title: '',
            body : data.Messages.Warning,
            type : 'warning',
            icon : ''
          })
        }
      }
    },

    onCartItemsChange () {
      if (this.totalCartItemsCount <= 0) {
        if (this.$route.name === 'CheckoutDetails' || this.$route.name === 'CheckoutPayment') {
          this.$router.replace({ name: 'Menu' })
        }
      }
    },

    onShowMenuOptionsDialog (data) {
      if (this.dataStore.loadingMenuItem) return
      this.dataStore.loadingMenuItem = true
      data = JSON.parse(JSON.stringify(data))

      this.menuOptionsDialogEditMode = data.editMode

      if (!data.editMode && data.menuItem?.DetailsData) {
        this.selectedMenuItem = data.menuItem.DetailsData
        this.selectedMenuItem.ModifierRuns = 0
        this.menuOptionsDialogVisible = true
      } else {
        const url = window.APICall.menuOptions + (data.editMode ? data.menuItem.Id + '/' + data.menuItem.RowId : data.menuItem.Id)
        window.API.get(url)
          .then(response => {
            if (response.data.status === 200) {
              this.selectedMenuItem = response.data.data
              this.selectedMenuItem.ModifierRuns = 0
              this.menuOptionsDialogVisible = true
            }
          })
          .catch(e => {
            this.dataStore.loadingMenuItem = false
          })
      }
    },

    saveMenuOptions (data) {
      data = JSON.parse(JSON.stringify(data))
      delete data.OptionsCopy

      this.cartAddItem(data)
      this.menuOptionsDialogVisible = false
      this.dataStore.loadingMenuItem = false
    },

    cartAddItem (menuItem) {
      if (this.isCatalogOnly) return

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.cartAdd, { menu: menuItem })
        .then(response => {
          if (response.data.status === 200) {
            const item = this.menuItemToGAItem(menuItem)
            DataEventService.Emit(DataEventEnum.EVENT, {
              Event  : DataEventEnum.ADD_TO_CART,
              Payload: {
                items            : [item],
                value            : (item.price - item.discount) * item.quantity,
                currency         : item.currency,
                cartTotalQuantity: response?.data?.data?.Cart.CartTotalQuantity || 0
              }
            })
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data.Cart)
          }
        })
        .catch(e => {})
        .finally(() => {
          this.$bus.$emit('cart:add:response', menuItem)
        })
      )
    },

    cartRemoveItem (menuItem) {
      if (menuItem.removeItemLoading || this.isCatalogOnly) return
      this.$set(menuItem, 'removeItemLoading', true)

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.cartRemove, { menu: menuItem })
        .then(response => {
          if (response.data.status === 200) {
            const item = this.menuItemToGAItem(menuItem)
            DataEventService.Emit(DataEventEnum.EVENT, {
              Event  : DataEventEnum.REMOVE_FROM_CART,
              Payload: {
                items            : [item],
                value            : (item.price - item.discount) * item.quantity,
                currency         : item.currency,
                cartTotalQuantity: response?.data?.data?.Cart.CartTotalQuantity || 0
              }
            })
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data.Cart)
          }
        })
        .catch(e => {})
        .finally(() => {
          this.$set(menuItem, 'removeItemLoading', false)
        })
      )
    },

    couponAdd () {
      if (!this.couponValue.trim() || this.isCatalogOnly) return

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.cartCoupon, {
        coupon: {
          Action: 'add',
          Code  : this.couponValue
        }
      })
        .then(response => {
          if (response.data.status === 200) {
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data.Cart)
          }
        })
        .catch(e => {})
        .finally(() => {})
      )
    },

    couponRemove () {
      if (this.isCatalogOnly) return

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.cartCoupon, {
        coupon: {
          Action: 'remove',
          Code  : this.dataStore.cart.CartTotals.coupon.code
        }
      })
        .then(response => {
          if (response.data.status === 200) {
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data.Cart)
          }
        })
        .catch(e => {})
        .finally(() => {})
      )
    },

    tipAdd (tipAmount) {
      if (!parseFloat(tipAmount) || this.isCatalogOnly) return
      this.$bus.$emit('cart-tip-loading', true)

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.cartTip, {
        tip: {
          Action: 'add',
          Amount: tipAmount
        }
      })
        .then(response => {
          if (response.data.status === 200) {
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data.Cart)
          }
        })
        .catch(e => {})
        .finally(() => {
          this.$bus.$emit('cart-tip-loading', false)
        })
      )
    },

    tipRemove () {
      if (this.isCatalogOnly) return

      this.$bus.$emit('cart-tip-loading', true)

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.cartTip, {
        tip: {
          Action: 'remove'
        }
      })
        .then(response => {
          if (response.data.status === 200) {
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data.Cart)
          }
        })
        .catch(e => {})
        .finally(() => {
          this.$bus.$emit('cart-tip-loading', false)
        })
      )
    },

    setOrderType () {
      if (!this.isDelivery && !this.isCollection) return
      if (this.IsLoading || this.isCatalogOnly) return
      this.IsLoading = true
      this.$bus.$emit('cart-order-type-loading', true)

      this.CartPromiseQueue.push(() => window.API.post(window.APICall.cartOrderType, {
        // orderType: this.dataStore.cart.OrderType
        orderType: this.dataStore.tmpOrderType
      })
        .then(response => {
          if (response.data.status === 200) {
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data.Cart, false)
          }
        })
        .catch(e => {})
        .finally(() => {
          this.IsLoading = false
          this.confirmDefaultAddress()
          this.$bus.$emit('cart:order-type-updated')
          this.$bus.$emit('cart-order-type-loading', false)
        })
      )
    },

    getCart () {
      this.CartPromiseQueue.push(() => window.API.get(window.APICall.cart)
        .then(response => {
          if (response.data.status === 200) {
            this.cartParseMessages(response.data.data)
            this.cartSetData(response.data.data)
          }
        })
        .catch(e => {})
        .finally(() => {})
      )
    }
  }
}
</script>

<style scoped>
/deep/ .v-expansion-panel__header {
  padding-left : 12px !important;
}
</style>
