export default {
  data () {
    return {
      submitButton: false
    }
  },
  props  : {},
  methods: {
    initMenuItem () {
      if (!this.selectedMenuItem.DefaultUnitQuantity) this.selectedMenuItem.DefaultUnitQuantity = this.selectedMenuItem.UnitQuantity
      if (!this.selectedMenuItem.OriginalUnitPrice) this.selectedMenuItem.OriginalUnitPrice = this.selectedMenuItem.Price

      if (this.selectedMenuItem.UnitId > 1) {
        this.selectedMenuItem.UnitQuantity = this.selectedMenuItem.MinimumQuantity > this.unitQuantity ? this.selectedMenuItem.MinimumQuantity : this.unitQuantity
      } else {
        this.selectedMenuItem.Quantity = this.selectedMenuItem.MinimumQuantity || this.quantity
      }

      this.$set(this.selectedMenuItem, 'OptionsCopy', JSON.parse(JSON.stringify(this.selectedMenuItem.Options)))
      this.onRadioChange()
      this.onCheckBoxChange()
    },

    incrementQuantity () {
      this.quantity++
    },

    decrementQuantity () {
      this.quantity = this.quantity <= parseInt(this.selectedMenuItem.MinimumQuantity) ? parseInt(this.selectedMenuItem.MinimumQuantity) : --this.quantity
    },

    incrementUnitQuantity () {
      this.unitQuantity += parseInt(this.selectedMenuItem.DefaultUnitQuantity)
    },

    decrementUnitQuantity () {
      this.unitQuantity = this.unitQuantity <= parseInt(this.selectedMenuItem.DefaultUnitQuantity) ? parseInt(this.selectedMenuItem.DefaultUnitQuantity) : this.unitQuantity - parseInt(this.selectedMenuItem.DefaultUnitQuantity)
    },

    calculateModifiers () {
      for (let i = 0; i < this.selectedMenuItem.Options.length; i++) {
        const item = this.selectedMenuItem.Options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'radio') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              if (option.MultiValues) {
                this.setModifiers(option)
              }
            }
          }
        }
      }
      if (this.selectedMenuItem.hasOwnProperty('ModifierRuns')) this.selectedMenuItem.ModifierRuns++
    },

    setModifiers (menuOptionValue) {
      if (!menuOptionValue.MultiValues) return

      if (menuOptionValue.MultiValues.hasOwnProperty('optionsModifiers')) {
        for (const optionId in menuOptionValue.MultiValues.optionsModifiers) {
          const option = this.selectedMenuItem.Options.find(menuOption => menuOption.Id == optionId)
          const optionModifier = menuOptionValue.MultiValues.optionsModifiers[optionId]

          if (optionModifier.hasOwnProperty('MaxChoices')) option.MaxChoices = optionModifier.MaxChoices
          if (optionModifier.hasOwnProperty('MinChoices')) option.MinChoices = optionModifier.MinChoices
          if (optionModifier.hasOwnProperty('Required')) option.Required = optionModifier.Required
          if (optionModifier.hasOwnProperty('Priority')) option.Priority = optionModifier.Priority
          if (optionModifier.hasOwnProperty('Visible')) {
            option.Visible = optionModifier.Visible
          } else {
            option.Visible = true
          }
        }
      }

      if (menuOptionValue.MultiValues.hasOwnProperty('optionValuesModifiers')) {
        for (const optionValueId in menuOptionValue.MultiValues.optionValuesModifiers) {
          const option = this.selectedMenuItem.Options.find(menuOption => menuOption.OptionValues.some(menuOptionValue => menuOptionValue.Id == optionValueId))
          const optionValue = option.OptionValues.find(menuOptionValue => menuOptionValue.Id == optionValueId)
          const optionModifier = menuOptionValue.MultiValues.optionValuesModifiers[optionValueId]

          if (optionModifier.hasOwnProperty('Priority')) optionValue.Priority = optionModifier.Priority
          if (optionModifier.hasOwnProperty('Disabled')) optionValue.Disabled = optionModifier.Disabled
          if (optionModifier.hasOwnProperty('Visible')) {
            optionValue.Visible = optionModifier.Visible
          } else {
            optionValue.Visible = true
          }
          if (optionModifier.hasOwnProperty('ShowPrice')) optionValue.ShowPrice = optionModifier.ShowPrice
          if (optionModifier.hasOwnProperty('Price')) {
            optionValue.Price = parseFloat(optionModifier.Price)
            optionValue.PriceFmt = parseFloat(optionModifier.Price)
          }

          if (optionModifier.hasOwnProperty('Selected') && ((this.selectedMenuItem.ModifierRuns > 0 && this.selectedMenuItem.RowId) || !this.selectedMenuItem.RowId)) {
            optionValue.Selected = optionModifier.Selected
          }
        }
      }
    },

    isRequiredOptionsValid () {
      if (!this.selectedMenuItem) return false
      let isValid = 0
      for (let i = 0; i < this.selectedMenuItem.Options.length; i++) {
        const item = this.selectedMenuItem.Options[i]
        let selectedOptionsCount = 0
        item.Error = false

        if (item.Required) {
          if (item.DisplayType === 'radio') {
            selectedOptionsCount = item.OptionValues.filter((option) => {
              return item.DefaultValueId.toString() === option.Id.toString()
            }).length
            if (selectedOptionsCount === 0) {
              item.Error = {
                Type      : 'MinChoices',
                Selected  : selectedOptionsCount,
                MinChoices: item.MinChoices,
                MaxChoices: item.MaxChoices,
                Message   : this.$tc('Cart.Error.MenuItemOption.MinChoices.Message', item.MinChoices, { MinChoices: item.MinChoices })
              }
            }
          }
        }

        if (item.DisplayType === 'checkbox') {
          selectedOptionsCount = item.OptionValues.filter((option) => {
            return option.Selected
          }).length

          if (selectedOptionsCount < item.MinChoices || (item.Required && selectedOptionsCount === 0)) {
            item.Error = {
              Type      : 'MinChoices',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : this.$tc('Cart.Error.MenuItemOption.MinChoicesRequired.Message', item.MinChoices, { MinChoices: item.MinChoices })
            }
          }
          if (selectedOptionsCount > item.MaxChoices && item.MaxChoices > 0) {
            item.Error = {
              Type      : 'MaxChoices',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : this.$tc('Cart.Error.MenuItemOption.MaxChoices.Message', item.MaxChoices, { MaxChoices: item.MaxChoices })
            }
          }
          if ((selectedOptionsCount < item.MinChoices || selectedOptionsCount > item.MaxChoices) && (item.MaxChoices >= item.MinChoices && item.MaxChoices > 0)) {
            item.Error = {
              Type      : 'Between',
              Selected  : selectedOptionsCount,
              MinChoices: item.MinChoices,
              MaxChoices: item.MaxChoices,
              Message   : item.MinChoices === item.MaxChoices
                ? this.$tc('Cart.Error.MenuItemOption.MinChoices.Message', item.MinChoices, { MinChoices: item.MinChoices })
                : (item.MinChoices === 0 && item.MaxChoices > 0)
                  ? this.$tc('Cart.Error.MenuItemOption.MaxChoices.Message', item.MaxChoices, { MaxChoices: item.MaxChoices })
                  : this.$tc('Cart.Error.MenuItemOption.Between.Message', item.MaxChoices, {
                    MinChoices: item.MinChoices,
                    MaxChoices: item.MaxChoices
                  })
            }
          }
        }

        if (item.Error) isValid++
      }
      return isValid === 0
    },

    onRadioClick (item, option) {
      item.DefaultValueId = option.Id
      this.onRadioChange()
    },

    onRadioChange () {
      if (!this.selectedMenuItem) return
      this.onUnitQuantityChange()
      this.calculateModifiers()
      this.isRequiredOptionsValid()
      this.calculateOptionsPrices()
    },

    getMenuUnitBasePriceWithOptions (menu) {
      if (!menu || menu.UnitId <= 1) return 0

      let optionsPrice = 0
      const hasDetailsData = !!menu?.DetailsData
      const options = hasDetailsData ? menu.DetailsData?.Options || [] : menu?.Options || []
      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]

          if (item.DisplayType === 'unit_value') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              optionsPrice = optionsPrice + parseFloat(option.Price)
            }
          }
        }
      }

      return parseFloat(parseFloat(parseFloat(hasDetailsData ? menu.PriceFmt : menu.OriginalUnitPrice) + parseFloat(optionsPrice)).toFixed(2))
    },

    onUnitQuantityChange () {
      if (!this.selectedMenuItem || this.selectedMenuItem.UnitId <= 1) return

      let optionsPrice = 0

      for (let i = 0; i < this.selectedMenuItem.Options.length; i++) {
        const item = this.selectedMenuItem.Options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'unit') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              this.selectedMenuItem.UnitQuantity = parseInt(option.Price)
              option.Selected = true
            } else {
              option.Selected = false
            }
          }

          if (item.DisplayType === 'unit_value') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              optionsPrice = optionsPrice + parseFloat(option.Price)
              option.Selected = true
            } else {
              option.Selected = false
            }
          }
        }
      }

      const measurementUnit = this.getMeasurementUnitById(this.selectedMenuItem.UnitId)
      const divisor = parseFloat(measurementUnit?.Divisor ?? 1)
      const menuBasePrice = parseFloat(this.selectedMenuItem.OriginalUnitPrice) + optionsPrice
      const price = parseFloat((menuBasePrice * divisor * parseInt(this.selectedMenuItem.UnitQuantity)).toFixed(2))

      this.selectedMenuItem.OriginalPrice = price
    },

    onCheckBoxClick (option) {
      if ((option.Mandatory && option.Selected) || option.Disabled) return

      option.Selected = !option.Selected
      this.onCheckBoxChange()
    },

    onCheckBoxChange () {
      if (!this.selectedMenuItem) return
      this.isRequiredOptionsValid()
      this.calculateOptionsPrices()
    },

    calculateOptionsPrices () {
      let price = 0
      let priceSpecial = 0

      if (this.selectedMenuItem.OriginalPrice) {
        price = parseFloat(this.selectedMenuItem.OriginalPrice)
      } else {
        this.selectedMenuItem.OriginalPrice = this.selectedMenuItem.Price
        price = parseFloat(this.selectedMenuItem.Price)
      }

      if (this.selectedMenuItem.OriginalSpecialPrice) {
        priceSpecial = parseFloat(this.selectedMenuItem.OriginalSpecialPrice)
      } else {
        this.selectedMenuItem.OriginalSpecialPrice = this.selectedMenuItem.SpecialPrice
        priceSpecial = parseFloat(this.selectedMenuItem.SpecialPrice)
      }

      if (!Array.isArray(this.selectedMenuItem.Options)) {
        this.selectedMenuItem.Options = Object.keys(this.selectedMenuItem.Options).map((k) => this.selectedMenuItem.Options[k])
      }

      for (let i = 0; i < this.selectedMenuItem.Options.length; i++) {
        const item = this.selectedMenuItem.Options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          const option = item.OptionValues[ii]
          if (item.DisplayType === 'checkbox') {
            if (option.Selected) {
              price = parseFloat(price) + parseFloat(option.Price)
              priceSpecial = parseFloat(priceSpecial) + parseFloat(option.Price)
            }
          } else if (item.DisplayType === 'radio') {
            if (item.DefaultValueId.toString() === option.Id.toString()) {
              option.Selected = true
              price = parseFloat(price) + parseFloat(option.Price)
              priceSpecial = parseFloat(priceSpecial) + parseFloat(option.Price)
            } else {
              option.Selected = false
            }
          }
        }
      }

      this.selectedMenuItem.Price = (this.selectedMenuItem.SpecialStatus && this.selectedMenuItem.IsSpecial) ? priceSpecial : price
      this.selectedMenuItem.PriceFmt = price
      this.selectedMenuItem.SpecialPrice = priceSpecial
      this.selectedMenuItem.SpecialPriceFmt = priceSpecial
    }
  },
  computed: {
    UseMenuLargeImage () {
      return this.appConfig.LOCATION_DATA && this.appConfig.LOCATION_DATA.hasOwnProperty('UseMenuLargeImage') ? this.appConfig.LOCATION_DATA.UseMenuLargeImage : false
    },

    UseMenuLandingPage () {
      return this.appConfig.LOCATION_DATA && this.appConfig.LOCATION_DATA.hasOwnProperty('UseMenuLandingPage') ? this.appConfig.LOCATION_DATA.UseMenuLandingPage : false
    },

    menuItemTotal () {
      if (this.selectedMenuItem.UnitId > 1) return this.selectedMenuItem.Price

      return this.quantity * this.selectedMenuItem.Price
    },

    menuItemHasUnitQuantityOptions () {
      const options = this.selectedMenuItem?.Options || []
      let retVal = false

      for (let i = 0; i < options.length; i++) {
        const item = options[i]
        for (let ii = 0; ii < item.OptionValues.length; ii++) {
          if (item.DisplayType === 'unit') {
            retVal = true
          }
        }
      }

      return retVal
    },

    quantity: {
      get () {
        let q = 0
        if (this.selectedMenuItem) q = parseInt(this.selectedMenuItem.Quantity) >= parseInt(this.selectedMenuItem.MinimumQuantity) ? parseInt(this.selectedMenuItem.Quantity) : parseInt(this.selectedMenuItem.MinimumQuantity)
        return q
      },
      set (val) {
        this.selectedMenuItem.Quantity = parseInt(val)
      }
    },

    unitQuantity: {
      get () {
        let q = 0
        if (this.selectedMenuItem) q = parseInt(this.selectedMenuItem.UnitQuantity) >= parseInt(this.selectedMenuItem.DefaultUnitQuantity) ? parseInt(this.selectedMenuItem.UnitQuantity) : parseInt(this.selectedMenuItem.DefaultUnitQuantity)
        return q
      },
      set (val) {
        this.selectedMenuItem.UnitQuantity = parseInt(val)
        this.onRadioChange()
      }
    }
  }
}
